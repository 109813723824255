import type { Editor } from 'tinymce';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import type { SafeColorCallback } from '../styles/SafeColor';

export interface EditorColorInfo {
  /**
   * 6-digit hex color preceded by a hash character (#).
   */
  hex: string;
  /**
   * CSS Var that represents the color.
   */
  cssVar: string;
}

const TEXT_COLOR_DATA = 'data-lia-text-color';
const TEXT_COLOR_CLASS_PREFIX = 'lia-text-color-';

/**
 * Get colors from CSS vars that are designated for use by the editor for text fore colors.
 * This looks for CSS Vars that are in the pattern: --lia-rte-custom-color-N where N is the
 * custom color number id.
 */
export function getEditorColorsFromCssVars(
  theme: ThemeResultFragment,
  safeColor: SafeColorCallback
): EditorColorInfo[] {
  const colors: EditorColorInfo[] = [];

  Object.entries(theme.rte)
    .filter(([key]) => key.startsWith('customColor'))
    .sort(([key1], [key2]) => {
      const id1 = Number.parseInt(key1.replace('customColor', ''));
      const id2 = Number.parseInt(key2.replace('customColor', ''));
      return id1 - id2;
    })
    .forEach(([key, value]) => {
      const id = key.replace('customColor', '');
      const colorKey = `--lia-rte-custom-color-${id}`;
      const color = safeColor(value).hex().toLowerCase();
      colors.push({ hex: color, cssVar: colorKey });
    });

  return colors;
}

function removeColorClassFromElement(element: Element): void {
  element.classList.forEach(className => {
    if (className.startsWith(TEXT_COLOR_CLASS_PREFIX)) {
      element.classList.remove(className);
    }
  });
}

function removeColorClassFromElementAndChildren(element: Element): void {
  removeColorClassFromElement(element);
  element.querySelectorAll(`[class^='${TEXT_COLOR_CLASS_PREFIX}']`).forEach(nestedElement => {
    removeColorClassFromElement(nestedElement);
  });
}

// Uses tinymce.d.ts/InlineFormat
export const forecolorFormat = {
  inline: 'span',
  attributes: { [TEXT_COLOR_DATA]: '%value' },
  remove: 'all',
  exact: true
};

/**
 * Registers an attribute filter that watches for `data-lia-text-color` data attribute.
 *
 * @param editor the TinyMCE Editor.
 * @param colors the hex string array.
 */
export default function registerForecolorHandler(editor: Editor, colors: string[]) {
  editor.on('preInit', () => {
    editor.serializer.addAttributeFilter(TEXT_COLOR_DATA, () => {
      editor
        .getBody()
        .querySelectorAll('[data-lia-text-color]')
        .forEach(element => {
          const selectedColor = element.getAttribute(TEXT_COLOR_DATA);

          const colorIndex = colors.indexOf(selectedColor.toLowerCase());
          if (colorIndex >= 0) {
            const colorClass = `${TEXT_COLOR_CLASS_PREFIX}${colorIndex + 1}`;
            removeColorClassFromElementAndChildren(element);
            element.classList.add(colorClass);
          }
          element.removeAttribute(TEXT_COLOR_DATA);
        });
    });
  });
}

import { EndUserPages } from '@aurora/shared-types/pages/enums';
import type { AstNode, Editor } from 'tinymce';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import { canUseDOM } from 'exenv';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  BoardPages,
  BoardPagesAndParams,
  CategoryPageAndParams,
  GroupHubPageAndParams,
  MessagePagesAndParams,
  MessageReplyPagesAndParams,
  UserPageAndParams
} from '../../routes/endUserRoutes';
import type { EndUserRouter } from '../../routes/useEndUserRoutes';
import { getHtmlElement } from './EditorHelper';

/**
 * Mention Type.
 */
export enum MentionType {
  USER = 'user',
  MESSAGE = 'message',
  NODE = 'node'
}

/**
 * Represents user mentions data.
 */
const UserMentionData = {
  uid: '',
  login: ''
};

/**
 * Represents message mentions data.
 */
const MessageMentionData = {
  uid: '',
  title: '',
  'content-type': '',
  'title-override': '',
  'topic-id': '',
  'topic-subject': '',
  'node-display-id': ''
};

/**
 * Represents node mentions data.
 */
const NodeMentionData = {
  'display-id': '',
  title: '',
  'node-type': '',
  'content-type': '',
  'parent-id': '',
  'title-override': ''
};

/**
 * Types for User, Message and Node mentions data.
 */
type UserMentionProps = typeof UserMentionData;
type MessageMentionProps = Partial<typeof MessageMentionData>;
type NodeMentionProps = Partial<typeof NodeMentionData>;

/**
 * A map which gives the mention data for a given mention type.
 */
const mentionTypeToPropsMap: Record<MentionType, MentionProps<MentionType>> = {
  [MentionType.USER]: UserMentionData,
  [MentionType.MESSAGE]: MessageMentionData,
  [MentionType.NODE]: NodeMentionData
};

/**
 * Mention Props for a given mention type.
 */
type MentionProps<TypeT extends MentionType> = TypeT extends MentionType.USER
  ? UserMentionProps
  : TypeT extends MentionType.MESSAGE
  ? MessageMentionProps
  : TypeT extends MentionType.NODE
  ? NodeMentionProps
  : never;

const DATA_USER_MENTIONS = 'data-lia-user-mentions';
const DATA_USER_UID = 'data-lia-user-uid';
const DATA_USER_LOGIN = 'data-lia-user-login';

const DATA_MESSAGE_MENTIONS = 'data-lia-message-mentions';
const DATA_MESSAGE_UID = 'data-lia-message-uid';
const DATA_MESSAGE_TITLE = 'data-lia-message-title';
const DATA_MESSAGE_CONTENT_TYPE = 'data-lia-message-content-type';
const DATA_MESSAGE_TOPIC_ID = 'data-lia-message-topic-id';
const DATA_MESSAGE_TOPIC_SUBJECT = 'data-lia-message-topic-subject';
const DATA_MESSAGE_NODE_DISPLAY_ID = 'data-lia-message-node-display-id';

const DATA_NODE_MENTIONS = 'data-lia-node-mentions';
const DATA_NODE_DISPLAY_ID = 'data-lia-node-display-id';
const DATA_NODE_TYPE = 'data-lia-node-node-type';
const DATA_NODE_CONTENT_TYPE = 'data-lia-node-content-type';
const DATA_NODE_PARENT_ID = 'data-lia-node-parent-id';
const MENTIONS_CLASS = 'lia-mention';
const SPACE = '&nbsp';

/**
 * Whether the specified element is a `mention` element
 *
 * @param element Element to verify
 */
const isMentionsElement = (element: HTMLElement) =>
  element && element.nodeName === 'A' && element.classList.contains(MENTIONS_CLASS);

/**
 * Get enum instance from value.
 *
 * @param enumObject the enum object.
 * @param value the enum value.
 */
function enumFromValue<EnumType>(
  enumObject: EnumType,
  value: keyof EnumType
): EnumType[keyof EnumType] {
  return Object.values(enumObject).find(enumObjectValue => enumObjectValue === value);
}

export type MentionPagesAndParams =
  | UserPageAndParams
  | MessagePagesAndParams
  | BoardPagesAndParams
  | CategoryPageAndParams
  | GroupHubPageAndParams
  | MessageReplyPagesAndParams;

/**
 * Get the route and route parameters to push when a mention element is clicked.
 *
 * @param mentionElement the mention element.
 * @param topicId topic ID of the reply mentioned.
 * @param topicSubject topic Subject of the reply mentioned.
 */
function getRouteAndParameters(
  mentionElement: HTMLElement,
  topicId?: string,
  topicSubject?: string
): MentionPagesAndParams | null {
  if (mentionElement.hasAttribute(DATA_USER_MENTIONS)) {
    return {
      route: EndUserPages.UserPage,
      params: {
        userId: mentionElement.getAttribute(DATA_USER_UID),
        login: mentionElement.getAttribute(DATA_USER_LOGIN)
      }
    };
  } else if (mentionElement.hasAttribute(DATA_MESSAGE_MENTIONS)) {
    const contentType = mentionElement.getAttribute(DATA_MESSAGE_CONTENT_TYPE);
    const contentTypeAsEnum = enumFromValue<typeof ConversationStyle>(
      ConversationStyle,
      contentType as keyof typeof ConversationStyle
    );
    let route;
    const contentTitle = mentionElement.getAttribute(DATA_MESSAGE_TITLE);
    if (!contentTitle.startsWith('Re:')) {
      switch (contentTypeAsEnum) {
        case ConversationStyle.Forum: {
          route = EndUserPages.ForumMessagePage;
          break;
        }
        case ConversationStyle.Blog: {
          route = EndUserPages.BlogMessagePage;
          break;
        }
        case ConversationStyle.Tkb: {
          route = EndUserPages.TkbMessagePage;
          break;
        }
        case ConversationStyle.Idea: {
          route = EndUserPages.IdeaMessagePage;
          break;
        }
        case ConversationStyle.Occasion: {
          route = EndUserPages.OccasionMessagePage;
          break;
        }
        default: {
          route = EndUserPages.ForumMessagePage;
          break;
        }
      }
      return {
        route,
        params: {
          boardId: mentionElement.getAttribute(DATA_MESSAGE_NODE_DISPLAY_ID),
          messageSubject: UrlHelper.determineSlugForMessagePath({
            subject: mentionElement.getAttribute(DATA_MESSAGE_TITLE)
          }),
          messageId: mentionElement.getAttribute(DATA_MESSAGE_UID)
        }
      };
    } else {
      switch (contentTypeAsEnum) {
        case ConversationStyle.Forum: {
          route = EndUserPages.ForumReplyPage;
          break;
        }
        case ConversationStyle.Blog: {
          route = EndUserPages.BlogReplyPage;
          break;
        }
        case ConversationStyle.Tkb: {
          route = EndUserPages.TkbReplyPage;
          break;
        }
        case ConversationStyle.Idea: {
          route = EndUserPages.IdeaReplyPage;
          break;
        }
        case ConversationStyle.Occasion: {
          route = EndUserPages.OccasionReplyPage;
          break;
        }
        default: {
          route = EndUserPages.ForumReplyPage;
          break;
        }
      }
      return {
        route,
        params: {
          boardId: mentionElement.getAttribute(DATA_MESSAGE_NODE_DISPLAY_ID),
          messageSubject: UrlHelper.determineSlugForMessagePath({
            subject: topicSubject || mentionElement.getAttribute(DATA_MESSAGE_TOPIC_SUBJECT)
          }),
          messageId: topicId || mentionElement.getAttribute(DATA_MESSAGE_TOPIC_ID),
          replyId: mentionElement.getAttribute(DATA_MESSAGE_UID)
        }
      };
    }
  } else if (mentionElement.hasAttribute(DATA_NODE_MENTIONS)) {
    const nodeType = mentionElement.getAttribute(DATA_NODE_TYPE);
    const nodeDisplayId = mentionElement.getAttribute(DATA_NODE_DISPLAY_ID).toLowerCase();
    const nodeTypeAsEnum = enumFromValue<typeof NodeType>(
      NodeType,
      nodeType as keyof typeof NodeType
    );

    switch (nodeTypeAsEnum) {
      case NodeType.BOARD: {
        const contentType = mentionElement.getAttribute(DATA_NODE_CONTENT_TYPE);
        const contentTypeAsEnum = enumFromValue<typeof ConversationStyle>(
          ConversationStyle,
          contentType as keyof typeof ConversationStyle
        );
        let route: BoardPages;

        switch (contentTypeAsEnum) {
          case ConversationStyle.Forum: {
            route = EndUserPages.ForumBoardPage;
            break;
          }
          case ConversationStyle.Blog: {
            route = EndUserPages.BlogBoardPage;
            break;
          }
          case ConversationStyle.Tkb: {
            route = EndUserPages.TkbBoardPage;
            break;
          }
          default: {
            route = EndUserPages.ForumBoardPage;
            break;
          }
        }
        return {
          route,
          params: {
            categoryId: mentionElement.getAttribute(DATA_NODE_PARENT_ID).toLowerCase(),
            boardId: nodeDisplayId
          }
        };
      }

      case NodeType.CATEGORY: {
        return {
          route: EndUserPages.CategoryPage,
          params: {
            categoryId: nodeDisplayId
          }
        };
      }

      case NodeType.GROUPHUB: {
        return {
          route: EndUserPages.GroupHubPage,
          params: {
            groupHubId: nodeDisplayId
          }
        };
      }
      default: {
        break;
      }
    }
  }
  return null;
}

/**
 * Set the `href` for a mention link.
 *
 * @param mentionElement the mention element.
 * @param router the router
 * @param prefixOrigin whether to prefix the origin path to the href.
 * @param topicId topic ID of the reply mentioned.
 * @param topicSubject topic Subject of the reply mentioned.
 */
function setHrefForMentionsElement(
  mentionElement: HTMLElement,
  router: EndUserRouter,
  getCaseSensitivePath: (value: string) => string,
  prefixOrigin = false,
  topicId?: string,
  topicSubject?: string
): void {
  const routeAndParams = getRouteAndParameters(mentionElement, topicId, topicSubject);
  if (routeAndParams) {
    const { route, params } = routeAndParams;
    const href = router.getRelativeUrlForRoute<MentionPagesAndParams>(route, params);
    const origin = prefixOrigin && canUseDOM && window.location.origin;
    const finalHref = origin ? new URL(href, origin).toString() : href;
    mentionElement.setAttribute('href', getCaseSensitivePath(finalHref));
  }
}

/**
 * Get the mentions node to replace the current node with.
 *
 * @param editor the TinyMce Editor.
 * @param template the HTML template.
 */
function getMentionsNode(editor: Editor, template: string): AstNode {
  const domParser = window.tinymce.html.DomParser(
    editor.editorManager.defaultOptions,
    editor.schema
  );
  return domParser.parse(template).firstChild;
}

/**
 * Get the mentions CSS class to apply for the anchor HTML.
 *
 * @param mentionType the mention type.
 * @param data the mention props.
 */
function getMentionsClass<TypeT extends MentionType>(
  mentionType: TypeT,
  data: MentionProps<TypeT>
): string {
  let mentionsClass = `${MENTIONS_CLASS} ${MENTIONS_CLASS}-${mentionType}`;
  const contentType = data['content-type'];
  const nodeType = data['node-type'];
  if (contentType) {
    mentionsClass += ` ${MENTIONS_CLASS}-content-type-${contentType}`;
  }
  if (nodeType) {
    mentionsClass += ` ${MENTIONS_CLASS}-node-type-${nodeType}`;
  }
  return mentionsClass;
}

/**
 * Get the inner HTML for the anchor tag.
 *
 * @param data the mention props.
 * @param mentionType the mention type.
 */
function getInnerHtml<TypeT extends MentionType>(data: MentionProps<TypeT>, mentionType: TypeT) {
  if (mentionType === MentionType.USER) {
    return (data as UserMentionProps).login;
  } else {
    return (data as MessageMentionProps | NodeMentionProps).title;
  }
}

/**
 * Create anchor link for the specified mention type.
 *
 * @param editor the TinyMce Editor.
 * @param mentionType the mention type.
 * @param data the attributes to set for the anchor element.
 */
function createMentionsHtml<TypeT extends MentionType>(
  editor: Editor,
  mentionType: TypeT,
  data: MentionProps<TypeT>
): string {
  const attrObject = {
    href: '',
    class: getMentionsClass(mentionType, data),
    [`data-lia-${mentionType}-mentions`]: '',
    contenteditable: 'false'
  };
  Object.entries(data).forEach(([key, value]) => {
    attrObject[`data-lia-${mentionType}-${key}`] = String(value);
  });
  return editor.dom.createHTML('a', attrObject, editor.dom.decode(getInnerHtml(data, mentionType)));
}

/**
 * Insert the mentions markup into the RTE.
 *
 * @param editor the TinyMCE Editor.
 * @param mentionType the mention type.
 * @param data the attributes to set for the anchor element.
 * @param router the router
 */
function insertMentionsMarkup<TypeT extends MentionType>(
  editor: Editor,
  mentionType: TypeT,
  data: MentionProps<TypeT>,
  router: EndUserRouter,
  getCaseSensitivePath: (value: string) => string
): void {
  const mentionsHtml = createMentionsHtml(editor, mentionType, data);
  const mentionsElement = getHtmlElement(editor, getMentionsNode(editor, mentionsHtml));
  setHrefForMentionsElement(
    mentionsElement,
    router,
    getCaseSensitivePath,
    true,
    data['topic-id'],
    data['topic-subject']
  );
  editor.undoManager.transact(() => {
    editor.insertContent(mentionsElement.outerHTML + SPACE);
    editor.execCommand('mceAutocompleterClose');
  });
}

/**
 * Register a node filter that converts `li-${mentionType}` node to HTML inserted into the editor.
 *
 * @param editor the TinyMce Editor.
 * @param mentionType the mention type.
 * @param router the router
 */
function registerXmlToHtmlConverter(
  editor: Editor,
  mentionType: MentionType,
  router: EndUserRouter,
  getCaseSensitivePath: (value: string) => string
): void {
  editor.parser.addNodeFilter(`li-${mentionType}`, nodes => {
    nodes.forEach(node => {
      const attrDef = mentionTypeToPropsMap[mentionType];
      const attrObject = {};
      Object.keys(attrDef).forEach(key => {
        attrObject[key] = node.attr(key);
      });
      const mentionsHtml = createMentionsHtml(editor, mentionType, attrObject);
      const mentionsElement = getHtmlElement(editor, getMentionsNode(editor, mentionsHtml));
      setHrefForMentionsElement(
        mentionsElement,
        router,
        getCaseSensitivePath,
        true,
        node.attr('topic-id'),
        node.attr('topic-subject')
      );
      node.replace(getMentionsNode(editor, mentionsElement.outerHTML));
    });
  });
}

/**
 * Whether the title for a mention has been overridden by the user.
 *
 * @param editor the TinyMCE Editor.
 * @param node the TinyMCE node.
 * @param mentionType the mention type.
 */
function isTitleOverride(editor: Editor, node: AstNode, mentionType: MentionType) {
  const originalTitle = node.attr(`data-lia-${mentionType}-title`);
  return originalTitle !== getHtmlElement(editor, node).textContent;
}

/**
 * Register an attribute filter on elements with data attribute `data-lia-${mentionType}-mentions`
 * that converts the HTML to corresponding `li-${mentionType}` node.
 *
 * @param editor the TinyMce Editor.
 * @param mentionType the mention type.
 */
function registerHtmlToXmlConverter(editor: Editor, mentionType: MentionType): void {
  editor.serializer.addAttributeFilter(`data-lia-${mentionType}-mentions`, nodes => {
    nodes.forEach(node => {
      const attrDef = mentionTypeToPropsMap[mentionType];
      const liMacroNode = new window.tinymce.html.Node(`li-${mentionType}`, 1);
      Object.keys(attrDef).forEach(key => {
        liMacroNode.attr(key, node.attr(`data-lia-${mentionType}-${key}`));
      });
      if (isTitleOverride(editor, node, mentionType)) {
        liMacroNode.attr('title', getHtmlElement(editor, node).textContent);
        liMacroNode.attr('title-override', 'true');
      }
      node.replace(liMacroNode);
    });
  });
}

/**
 * Register converters for user, message and node mentions.
 *
 * @param editor the TinyMCE Editor.
 * @param router the router
 */
function registerMentionsConverters(
  editor: Editor,
  router: EndUserRouter,
  getCaseSensitivePath: (value: string) => string
): void {
  editor.on('preInit', () => {
    Object.values(MentionType).forEach(mentionType => {
      registerXmlToHtmlConverter(editor, mentionType, router, getCaseSensitivePath);
      registerHtmlToXmlConverter(editor, mentionType);
    });
  });
}

export {
  getRouteAndParameters,
  registerMentionsConverters,
  insertMentionsMarkup,
  setHrefForMentionsElement,
  getMentionsNode,
  isMentionsElement
};
